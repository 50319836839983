import React, { useState } from "react";
import DonateBug from "./Components/DonateBug";
import Header from "./ContentComponents/Header";
import { GlobalStyle, Anchor, Button } from "./Styles";
import styled from "styled-components";
import About from "./ContentComponents/About";
import Goals from "./ContentComponents/Goals";
import Galleries from "./ContentComponents/Galleries";
import QuoteSlider from "./Components/QuoteSlider";
import { MdMenu, MdClose } from "react-icons/md";
import Menu from "./ContentComponents/Menu";
import { Transition } from "react-transition-group";
import Narrative2 from "./ContentComponents/Narrative2";
import { NarContext } from "./ContentComponents/NarContext";
import Stats from "./ContentComponents/Stats";
import Footer from "./Components/Footer";
// import ModalVolunteer from "./Components/ModalVolunteer";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [isNarrativesOpen, setIsNarrativesOpen] = useState(false);
  const [author, setAuthor] = useState(null);

  const ToggleState = () => {
    setIsOpen(!isOpen);
    setIsNarrativesOpen(false);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const openNarratives = () => {
    if (isNarrativesOpen) {
      const NarrativeSection = document.getElementById("narratives-page");
      NarrativeSection.scrollTo({ behavior: "smooth" });
    } else {
      setIsNarrativesOpen(true);
    }
  };

  // const [modal, setModal] = useState(true);

  // const modalOff = () => {
  //   setModal(false);
  //   console.log("modal");
  // };

  return (
    <>
      {/* {modal ? <ModalVolunteer modalOff={modalOff} /> : null} */}

      <div className="App">
        <Anchor id="anchor">
          <GlobalStyle />

          <Nav>
            <MenuButton onClick={ToggleState}>
              {isOpen ? <MdClose style={{ color: "white" }} /> : <MdMenu />}
            </MenuButton>
            <DonateBug />
          </Nav>
          <div>
            <Transition in={isOpen} timeout={300}>
              {(state) => <Menu closeMenu={closeMenu} state={state} />}
            </Transition>
          </div>

          <Section id="ID">
            <div className="text-section">
              <Header />
              <About />
            </div>
            <Hero src="./images/tendermoment.jpg" alt="With Patient" />
          </Section>
          <Stats />
          <Section id="prevTrips">
            <IMG src="./images/withpatient.jpg" alt="With Patient" />

            <Galleries />
          </Section>
          <div id="narratives">
            <NarContext.Provider value={{ author, setAuthor }}>
              <QuoteSlider openNarratives={openNarratives} />
            </NarContext.Provider>
          </div>
          <NarContext.Provider value={{ author, setAuthor }}>
            {isNarrativesOpen ? (
              <NarrativeSection id="narratives-page">
                <IMG src="./images/IMG_0328.jpg" alt="GLORI" />
                <Narrative2 />
              </NarrativeSection>
            ) : null}
          </NarContext.Provider>
          <Section id="goals">
            <Goals />
            <IMG src="./images/operation.jpg" alt="Surgeons operating" />
          </Section>
          <Footer />
        </Anchor>
      </div>
    </>
  );
}

export default App;

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  z-index: 101;
  width: 100%;

  padding: 1rem 1.6187rem 1rem 1.6187rem;

  @media (min-width: 768px) {
    padding-left: 4vw;
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row-reverse;
    /* scroll-snap-align: start; */

    :nth-child(3) {
      /* flex-direction: row-reverse; */
    }

    .text-section {
      max-width: 50vw;
    }
  }
`;

const NarrativeSection = styled(Section)`
  @media (min-width: 768px) {
    scroll-snap-align: none;
  }
`;

const IMG = styled.img`
  object-fit: cover;
  width: 100vw;
  aspect-ratio: 1/1;

  @media (min-width: 768px) {
    width: 50vw;
    height: 100vh;
    position: sticky;
    top: 0;
  }
`;

const Hero = styled(IMG)`
  display: none;

  @media (min-width: 768px) {
    display: block;

    position: relative;
    width: 50vw;
    height: 100vh;
    position: sticky;
    top: 0;
  }
`;

const MenuButton = styled(Button)`
  font-size: 1.6187rem;
  color: white;
  background-color: var(--blue);
  padding: 10px 10px 4px 10px;
`;
