import React, { useContext, useEffect } from "react";
import { Text, Title } from "../Styles";
import { NarContext } from "./NarContext";
import { NarContent } from "../Data/Narratives";
import styled from "styled-components";

const Narrative2 = () => {
  const { author } = useContext(NarContext);
  const int = author - 1;

  useEffect(() => {
    const NarrativeSection = document.getElementById("narratives-page");
    NarrativeSection.scrollIntoView({ behavior: "smooth" });
  });

  return (
    <div className="section-partition" id="narratives-page">
      <Text>
        <Title>{NarContent[int].author}'s Story:</Title>
        <Content
          dangerouslySetInnerHTML={{ __html: NarContent[int].writing }}
        ></Content>
      </Text>
    </div>
  );
};

export default Narrative2;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
