import React from "react";
import VideoSrc from "../Assets/glori_video.mp4";

const Video = () => {
  return (
    <>
      <video width="100%" height="auto" type="video/mp4" controls>
        <source src={VideoSrc} />
      </video>
    </>
  );
};

export default Video;
