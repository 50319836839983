import React from "react";
import Iframe from "react-iframe";

const Footer = () => {
  return (
    <div id="subscribe">
      <Iframe
        width="100%"
        height="750"
        src="https://a647349f.sibforms.com/serve/MUIFAJvIOphJDFsnm81kNUYiP3w6XpYW5kUjBR1yErQlFDs-O_EAmywCa7tM4IO6jGRc3nrXbEN5citGAjsOzz0ybEka96nXP-NSwuaV-QdaJTQI_4wHWRPtcxFZjEhkHZhdP-WMu4uHGW6Qg6Ap1lU2Bq4S89j279zFzNnVEgTLhAOYVJaLfs8JgLdOqrkkY2KQjr_nCjd0dq9d"
        frameborder="0"
        scrolling="auto"
        allowfullscreen
      />
    </div>
  );
};

export default Footer;
