/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { Text, Title, FlexContainer, Btn } from "../Styles";
import styled from "styled-components";
import Video from "./Video";
import NewsAndUpdates from "./NewsAndUpdates";

const About = () => {
  return (
    <div className="section-partition" id="about">
      <Text>
        <Title>About Our Organization</Title>
        <FlexContainer>
          <p>
            GLORI, based in Ann Arbor, MI, is an Orthopedic surgical outreach
            program to La Romana, Dominican Republic.
          </p>
          <p>
            We are a 501(c)(3) Not-For-Profit Organization that serves the poor
            and injured in the Dominican Republic. We provide planning,
            logistical and financial support for various orthopedic medical
            missions.
          </p>
          <p>
            A team of 13 licensed, experienced and dedicated health care
            professionals travel to La Romana, Dominican Republic to volunteer
            at El Buen Samaritano Hospital, a non-profit charitable hospital
            that provides care for local residents and Haitian immigrants. The
            patients we serve have limited resources and no other options for
            treatment
          </p>
          <img src="./images/001.jpg" alt="Group" />
          <ul>
            The typical team is made up of experienced health care providers
            including:
            <li>4-6 Orthopedic Surgeons including trainees</li>
            <li>
              Anesthesiologist and certified nurse anesthetists, surgical
              technicians and nurses
            </li>
            <li>2 CRNAs (Certified Registered Nurse Anesthetist)</li>
            <li>2 Surgical Techs</li>
            <li>1 Orthopedic Nurse</li>
            <li>1 Floor Nurse</li>
          </ul>
        </FlexContainer>
      </Text>
      <Text>
        <Iframe
          src="https://player.vimeo.com/video/805319944?h=ee6920c031
          "
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        />
        <CTA>
          <a
            href=" https://player.vimeo.com/video/784657373?h=eb8c222bf7"
            target="_blank"
          >
            Watch our video from 2022
          </a>
        </CTA>

        <Video />
      </Text>
      <Text id="news">
        <NewsAndUpdates />
      </Text>

      <Text>
        <Title>Interested in Volunteering?</Title>
        <p>
          Check out our Volunteer Information Page OR go directly to filling out
          our{" "}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScMqrq5W7dyX2rZWtesYeeNrTCHqZQRoy9P68o5-FPxIWK8hw/viewform"
            target="_blank"
            rel="noreferrer"
          >
            Volutneer Interest form.
          </a>{" "}
          We are currently recruiting for a trip in April 2024.
        </p>
        <Btn href="/volunteer" target="_blank" className="button">
          Volunteer Information Page
        </Btn>
      </Text>
    </div>
  );
};

export default About;

const Iframe = styled.iframe`
  border: none;

  aspect-ratio: 16 / 9;
  height: 100%;
  width: 100%;
`;

const CTA = styled.p`
  font-weight: 600;
  font-size: 1em;
  margin: 1em 0;
  text-transform: uppercase;
  a {
    text-decoration: underline;
  }
`;
