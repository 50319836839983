import React from "react";
import { Text, Title, FlexContainer } from "../Styles";
import styled from "styled-components";
import CTAButton from "../Components/CTAButton";

const Donations = () => {
  return (
    <div id="donations">
      <Text>
        <Title>Donations</Title>
        <FlexContainer>
          <p>
            Your generous support can help our volunteers, a team of experienced
            and licensed health care providers, save a life or a limb!
          </p>
          <p>
            Each trip costs about <strong>$25K</strong>.
          </p>
          <p>
            Each trip requires personnel to provide quality health care to the
            patients. The health care professionals who volunteer use personal
            vacation time or unpaid time off to donate their services on these
            trips. We would like to support our team of volunteers by covering
            the costs of travel and room and board.
          </p>
          <CostBreaks>
            <p>
              GLORI provides all the medical materials when they are there. (El
              Buen Samaritano Hospital is supportive of these trips but does not
              have the financial ability to provide any materials.) Your
              donations will go towards the following costs:
            </p>
            <li>
              <Cost>$2500</Cost>
              <Item>
                pays for surgical drapes, gowns, and gloves for the whole team
                for 1 week
              </Item>
            </li>
            <li>
              <Cost>$2000</Cost>
              <Item>pays for implants (plates and screws)</Item>
            </li>
            <li>
              <Cost>$1500</Cost>
              <Item>
                pays for one volunteer registered nurse, CRNA or scrub tech’s 1
                week trip
              </Item>
            </li>
            <li>
              <Cost>$500</Cost>
              <Item>provides room and board for several team members</Item>
            </li>
            <li>
              <Cost>$100-500</Cost>
              <Item>
                covers the cost of healthcare materials: oxygen tank, surgery
                clean-up, medications, plasters, x-rays, surgical prep soap,
                sutures, dressing supplies, suction, etc.
              </Item>
            </li>
            <li>
              <Cost>$100</Cost>
              <Item>
                pays for transportation for a Hatian immigrant to get to the
                hospital for clinic or the OR
              </Item>
            </li>
            <li>
              <Cost>$75</Cost>
              <Item>
                pays for an interpreter to help the volunteers understand and
                connect with the patients
              </Item>
            </li>
            <li>
              <Cost>$50</Cost>
              <Item>pays for a day of food and shelter for a volunteer</Item>
            </li>
          </CostBreaks>
        </FlexContainer>
      </Text>
      <Text>
        <CTAButton></CTAButton>
      </Text>
    </div>
  );
};

export default Donations;

const CostBreaks = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  li {
    display: grid;
    grid-template-columns: 6em 1fr;
    margin: 0;
    padding: 0;
  }
`;

const Cost = styled.div`
  display: block;
  font-weight: 900;
`;

const Item = styled.div``;
