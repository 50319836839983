export const NarContent = [
  {
    author: "Barbara",
    writing: `<p>First, a few thoughts about the mission:</p>
  <ul>
    <li>The Caribbean Island of Hispaniola is home to two countries: Haiti and Dominican Republic.</li>
    <li>
      Both have populations of around 10 million.
    </li>

    <li>
      Many individuals find their way to DM to work in sugarcane factories.

    </li>
    <li>
      Several of our patients are in very poor health without medical insurance.
    </li>
    <li>
      One night I couldn’t sleep, so I went down to the mission kitchen and experienced such simplicity…. With merengue music playing from a small transistor radio one security guard was playing checkers with a friend.

    </li>

    <li>
      Simple meals with totally yummy desserts! Double layer chocolate cake.

    </li>


    <li>
      Comradeship with your dorm mates, learning about their lives and interests.

    </li>

    <li>You learn about yourself, one's tolerances, untapped abilities, and strengths, how little one needs to live on.
    </li>
    <li>A young female patient tried conversing with me during her anesthesia… attempting to use the google Spanish to English translator…her response to asking how she was doing, went something like “ don’t cut longer because after that scar you look ugly” … we reassured her that our surgeons were the best!</li>
  </ul>

  <p><strong>November 2021, my best story</strong>:</p>

  <p>Experiencing a lunar eclipse on the rooftop at the mission house, in the Dominican Republic, the longest in 600 years…an experience that parallels that of the medical mission.</p>
   
  <p>
    I woke at 3</br>
    I rose at 4</br>
    And went out in the dark</br>
    By the mission kitchen door.</br>
    Someone was stealing the moon!
  </p>
   
   
  <p>
    Myranda said, don’t worry about the moon</br>
    We are due in the OR soon!</br>
    But the present</br>
    Was too precious to waste on sleep</br>
    
  </p>
   
  <p>
    In the dark of night…we sat…then light!</br>
    Top left, a silver light shone bright</br>
    Slowly, light returned, left to right</br>
    Until the full moon was reborn.</br>
    Shining anew with a fresh, dazzling light.
  </p>
   
  <p>
    Darkness tries to eclipse light</br>
    But as long as there is love</br>
    Shadows remain illusions and only that.</br>
  </p>
   
  <p>
    If the world stopped for bad stuff, then everything would be dark. But the world keeps going so that we can find the crack of light that leads to healing. The only thing people regret is that they didn’t live boldly enough, that they didn’t invest enough heart, didn’t love enough.
    
  </p>`,
  },
  {
    author: "Felicia",
    writing: `<p>
I agreed to attending the mission without really knowing much about
the culture or situation in the Dominican Republic. I was surprised to
find out that we were caring for Haitians primarily. The Haitians that
come to DR to work and try to have a safer life aren’t treated well.
They don’t always have access to safe water, food, housing and health
care. Many Haitians in DR live in areas called Bateys. These
communities often don’t have running water or electricity.
</p>

<p>
It is difficult because there is a huge need for medical help and we
don’t have the time or the equipment to help everyone. During the
clinic on Sunday over 70 people arrived hoping that we could help
them. In November, over the course of one week, the team performed 27
surgeries. I was one of the CRNAs that attended. The week was filled
with many challenges and long days. The hospital where we performed
the surgeries is called El Buen Samaritan’s. The staff there were
welcoming and helpful. They seemed very supportive of our work. Still
it was challenging as all of the anesthesia equipment, supplies, and
medications were not like we were used to. We had to bring as many
supplies as possible to make sure we could help as many people as
possible.
</p>

<p>
One specific story I can tell is about a young man we cared for. He
was around 17 or 18 and arrived at the clinic with his father. His leg
had been injured in a scooter/ motorcycle accident and had healed
poorly. Every step seemed painful and he walked with a cane. He
reminded me a bit of my teenage son. Trying to dress and act tough, a
little angry at the adults in his life and embarrassed by having to be
seen out in public with a parent. Craving his independence but not
having a clue how to get it. I remember him also because his cane was
broken and slippery on the bottom. I took the cane down to the OR area
while he was seen by the surgeons and did what I could do to make his
cane function better. I basically splinted it and wrapped it with
surgical tape. A few days later I was walking through the lab area
from the OR to where we ate lunch and saw the patient and his father.
The young man had to get his covid test before we did surgery on him
the next day. His father seemed so happy to see me and was desperately
trying to communicate in Spanish with me. His son again looked annoyed
and embarrassed. I found an interpreter and through her found out that
his son couldn’t work because of his barely functioning leg. The
father was so worried his son would end up getting into trouble if he
wasn’t able to have a legitimate job. No surgeons in the Dominican
Republic had been able to offer his son any options other than a cane.
He wanted me to know how thankful he was for us coming there and
helping. He said “God bless the Americans”.
</p>

<p>
A great thing that happened in our team is that the roles of surgical
tech, surgeon, nurse, anesthetist etc. got blurred and we were just a
team doing whatever needed to be done. We all looked out for each
other and the hierarchical pressures were erased. We all pitched in to
give the best care possible.
</p>

<p>
In short I am proud of the work we did. It was an honor to be a part
of the mission. It is important to remember that by pure luck and by
no feat of my own that I won some kind of social lottery to have the
privileges I have.
</p>`,
  },

  {
    author: "Mark",
    writing: ` <p>
Everytime I go to the DR to do surgeries I want to do more, so I keep organizing trips to go back.
The orthopedic surgeries we are performing in the DR are no different than the daily surgeries we do in the States. The difference is that there are plenty of resources here so people can get the care they need easily enough. In the DR, with the vulnerable populations we serve, if we were not there to help, there is nothing. There is no social safety net in the DR for the people who can’t pay for care. No Medicaid. No insurance. Out of pocket or nothing. This has a negative impact on the whole social structure, not only the individual we help. Care for the individual leads to communal good. Most of the patients we see are unable to work due to their injuries. If they can’t work they are a burden on their families because they can’t provide for anyone. This puts strain on the family as well as the community. Never mind the mental health and emotional stress this causes. Our work puts people back to work so they can be contributing members to society.
</p>

<p>One young man, 17 years old, had a fractured tibia and couldn’t walk. The injury would not heal on its own and he was looking at a long-term disability. He played baseball before the injury and he was very depressed because he couldn’t play anymore.We performed a successful surgery and I am eager to get back for a follow-up to see how he is healing. If we had better resources we could begin a surgery follow-up program in order to make sure our patients are seeing the recovery they need.</p>`,
  },
  {
    author: "Tom",
    writing: `<p>Several years ago while on a mission trip a 15 year old boy from an orphanage, with no adult accompanying him, came into the clinic on the last day we were there. He had a club foot, which is a congenital deformity where his foot is turned 90 degrees inward and he walks on the outside of his ankle. We don’t see this in the U.S. because it is fixed at birth. And the longer a club foot goes untreated the more risk is involved in a surgical intervention. Because it was the last day of our trip we didn’t have time to treat him and this really bothered me so I got his name, contact info and took his picture b/c I wanted to make sure I was able to treat him on our next visit. Unfortunately, due to Covid, our next trip was canceled. I thought a lot about him, regretting again that I was not able to treat him before. Our last trip in November of 2021 I was eager to find him. Early in the week I saw a young man of 18 in the clinic and I knew right away it was him. I lit up because I was so excited to see him with enough time to treat him. I went right up to him and said “Do you remember me?” He did not, so I went through my phone and found his picture from years ago. He smiled and said, “Oh yes, that is me! I remember you now.” I was able to get him into surgery right away. Due to the complicated nature of 18 years of club foot it was an intense surgery and lasted 4 hours. The team was exhausted but thrilled, it was a successful surgery! I am looking forward to our next visit in April so I can see him for a follow-up and make sure his recovery is going smoothly. If we had better funding we could support the Dominican Orthopedic Surgeons with more thorough follow-up with patients on post surgical treatments. I feel nervous for him because I don’t know how his recovery has been and am very eager to see him again in April.</p>
  `,
  },
];
