import React from "react";
import styled from "styled-components";
import { AiFillMail, AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import Logo from "../Assets/logowht.svg";

const Menu = ({ closeMenu, state }) => {
  const data = [
    { url: "about", title: "About GLORI" },
    { url: "news", title: "News & Updates" },
    { url: "prevTrips", title: "Previous Trips" },
    { url: "narratives", title: "Testimonials" },
    { url: "goals", title: "Goals" },
    { url: "donations", title: "Donations" },
    { url: "subscribe", title: "Subscribe to Newsletter" },
  ];

  const handleClick = (event, param) => {
    closeMenu();
    const element = document.getElementById(param);
    element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <MenuContainer $state={state}>
        <LogoContainer>
          <img src={Logo} alt="GLORI" />
        </LogoContainer>
        <MenuItems>
          {data.map((item, i) => (
            <MenuItem
              className="menu-item"
              src={item.url}
              key={i}
              url={item.url}
              onClick={(event) => {
                handleClick(event, `${item.url}`);
              }}
            >
              {item.title}
            </MenuItem>
          ))}
        </MenuItems>
        <Contact>
          <ContactFlexContainer>
            <AiFillMail />
            <a href="mailto:greatlakesori@yahoo.com">greatlakesori@yahoo.com</a>
          </ContactFlexContainer>
          <ContactFlexContainer>
            <AiFillInstagram />
            <a
              href="https://www.instagram.com/greatlakesortho_international/"
              target="_blank"
              rel="noreferrer"
            >
              @greatlakesortho_international
            </a>
          </ContactFlexContainer>

          <ContactFlexContainer>
            <AiFillFacebook />
            <a href="https://www.facebook.com/greatlakesori">
              GLORI on Facebook
            </a>
          </ContactFlexContainer>
        </Contact>
        <BgPattern id="menu-bg-pattern" />
      </MenuContainer>
      <BG $state={state} onClick={closeMenu} />
    </>
  );
};

export default Menu;

export const MenuContainer = styled.section`
  min-height: 100vh;
  width: 100vw;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  top: 0;
  right: ${({ $state }) =>
    $state === "entering" || $state === "entered" ? 0 : "-100vw"};
  transition: 300ms;
  @media (min-width: 768px) {
    width: 50vw;
    right: ${({ $state }) =>
      $state === "entering" || $state === "entered" ? 0 : "-50vw"};
  }
`;

const MenuItems = styled.div`
  z-index: 2;
  margin-right: auto;
  padding: 0 4vw;

  :hover > .menu-item {
    opacity: 0.3;
    transition: opacity 0.5s ease-in-out;
  }

  :hover > .menu-item:hover {
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
  }

  @media (min-width: 768px) {
  }
`;

const MenuItem = styled.a`
  color: #fff;
  font-size: 1.6187rem;
  line-height: 1.2;
  display: block;
  cursor: pointer;
  padding: 0.6187rem 0;

  :hover {
    color: #fff;
  }

  @media (min-width: 768px) {
    font-size: 2rem;
    padding: 1rem 0;
  }
`;

const BgPattern = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  min-height: 100vh;
  width: 100vw;

  background-color: var(--blue);

  @media (min-width: 768px) {
    width: 50vw;
  }
`;

export const BG = styled.div`
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  z-index: 2;
  opacity: ${({ $state }) =>
    $state === "entering" || $state === "entered" ? 1 : 0};
  pointer-events: ${({ $state }) => ($state === "exited" ? "none" : "auto")};
  transition: 300ms;
`;

const Contact = styled.div`
  z-index: 100;
  margin-top: 1rem;
  padding-bottom: 75px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* border-top: 1px solid white; */
  align-self: center;
`;

const ContactFlexContainer = styled.div`
  margin-right: auto;
  padding: 0 4vw;
  display: flex;
  align-items: center;
  gap: 1rem;
  > *,
  a:hover {
    color: #fff;
    font-size: 1rem;
  }
`;

const LogoContainer = styled.div`
  align-self: center;
  z-index: 100;
  margin-top: 1rem;
  padding: 0 4vw;
  max-width: 200px;
  opacity: 0.2;

  img {
    max-width: 90vw;
  }
  @media (min-width: 768px) {
    width: 45vw;

    img {
      max-width: 90vw;
    }
  }
`;
