import React, { useState, useEffect } from "react";
import { Text, Title, FlexContainer, Divider } from "../Styles";
import styled from "styled-components";
import ImageGallery from "../Components/ImageGallery";
import { img2021 } from "../Data/Images2021";
import { img2022 } from "../Data/Images2022";
import { imgnov2022 } from "../Data/ImagesNov2022";
import { imgApril2023 } from "../Data/ImagesApril2023";
import { imgApril2024 } from "../Data/ImagesApril2024";
import { imgNov2023 } from "../Data/ImagesNov2023";
import { imgTeamPhotos } from "../Data/Imagesteamphotos";

const Galleries = () => {
  const initialGalleryStates = [
    { isOpen: true }, // April 2024
    { isOpen: false }, // November 2023
    { isOpen: false }, // April 2023
    { isOpen: false }, // November 2022
    { isOpen: false }, // April 2022
    { isOpen: false }, // 2021
    { isOpen: false }, // Team Photos
  ];

  const [galleryStates, setGalleryStates] = useState(initialGalleryStates);
  const [shouldScroll, setShouldScroll] = useState(false);

  const toggleGallery = (index) => {
    const updatedStates = galleryStates.map((gallery, i) => ({
      isOpen: i === index ? !gallery.isOpen : false,
    }));

    setGalleryStates(updatedStates);
    setShouldScroll(true);
  };

  useEffect(() => {
    if (shouldScroll) {
      const openIndex = galleryStates.findIndex((gallery) => gallery.isOpen);

      if (openIndex !== -1) {
        scrollToGallery(openIndex);
      }

      setShouldScroll(false);
    }
  }, [galleryStates, shouldScroll]);

  const scrollToGallery = (index) => {
    const galleryId = `gallery${index}`;
    const galleryElement = document.getElementById(galleryId);

    if (galleryElement) {
      galleryElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="section-partition">
      <Text>
        <Title>Previous Trips</Title>

        <FlexContainer>
          <h3>April 2024</h3>
          <>
            <Toggle onClick={() => toggleGallery(0)}>
              {galleryStates[0].isOpen
                ? "[-] Click to Close"
                : "[+] Click to Open"}
            </Toggle>
            <div id="gallery0">
              {galleryStates[0].isOpen ? (
                <ImageGallery data={imgApril2024} />
              ) : null}
            </div>

            <Divider />
          </>
        </FlexContainer>

        <Flex>
          <h3>November 2023</h3>
          <>
            <Toggle onClick={() => toggleGallery(1)}>
              {galleryStates[1].isOpen
                ? "[-] Click to Close"
                : "[+] Click to Open"}
            </Toggle>
            <div id="gallery1">
              {galleryStates[1].isOpen ? (
                <ImageGallery data={imgNov2023} />
              ) : null}
            </div>

            <Divider />
          </>
        </Flex>

        <Flex>
          <h3>April 2023</h3>
          <>
            <Toggle onClick={() => toggleGallery(2)}>
              {galleryStates[2].isOpen
                ? "[-] Click to Close"
                : "[+] Click to Open"}
            </Toggle>
            <div id="gallery2">
              {galleryStates[2].isOpen ? (
                <ImageGallery data={imgApril2023} />
              ) : null}
            </div>

            <Divider />
          </>
        </Flex>

        <Flex>
          <h3>November 2022</h3>
          <>
            <Toggle onClick={() => toggleGallery(3)}>
              {galleryStates[3].isOpen
                ? "[-] Click to Close"
                : "[+] Click to Open"}
            </Toggle>
            <div id="gallery3">
              {galleryStates[3].isOpen ? (
                <ImageGallery data={imgnov2022} />
              ) : null}
            </div>

            <Divider />
          </>
        </Flex>

        <Flex>
          <h3>April 2022</h3>

          <>
            <Toggle onClick={() => toggleGallery(4)}>
              {galleryStates[4].isOpen
                ? `[-] Click to Close`
                : "[+] Click to Open"}
            </Toggle>
            <div id="gallery4">
              {galleryStates[4].isOpen ? <ImageGallery data={img2022} /> : null}
            </div>
            <Divider />
          </>
        </Flex>

        <Flex>
          <h3>2021</h3>

          <>
            <Toggle onClick={() => toggleGallery(5)}>
              {galleryStates[5].isOpen
                ? `[-] Click to Close`
                : "[+] Click to Open"}
            </Toggle>
            <div id="gallery5">
              {galleryStates[5].isOpen ? <ImageGallery data={img2021} /> : null}
            </div>

            <Divider />
          </>
        </Flex>

        <Flex>
          <h3>Team Photos</h3>

          <>
            <Toggle onClick={() => toggleGallery(6)}>
              {galleryStates[6].isOpen
                ? `[-] Click to Close`
                : "[+] Click to Open"}
            </Toggle>
            <div id="gallery6">
              {galleryStates[6].isOpen ? (
                <ImageGallery data={imgTeamPhotos} />
              ) : null}
            </div>

            <Divider />
          </>
        </Flex>
      </Text>
    </div>
  );
};

export default Galleries;

const Toggle = styled.h4`
  /* text-decoration: underline; */
  color: var(--gray-text);
  cursor: pointer;
`;

const Flex = styled(FlexContainer)`
  margin-top: 1.6187rem;
`;
