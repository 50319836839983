import React from "react";
import styled from "styled-components";

const CTAButton = (props) => {
  return (
    <CTAContainer>
      {props.children ? <CTA>{props.children}</CTA> : null}

      <a
        href="http://paypal.com/us/fundraiser/charity/4681460"
        target="_blank"
        rel="noreferrer"
      >
        <Button>Donate Today</Button>
      </a>
    </CTAContainer>
  );
};

export default CTAButton;

const CTAContainer = styled.div`
  order: 5;
  display: grid;
  place-items: center;
  gap: 1.6187rem;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  background: var(--red);
  border: 1px solid var(--dark-red);

  cursor: pointer;

  :active {
    transform: scale(0.95);
  }

  a {
    color: #fff;
  }

  a:hover {
    color: #fff;
  }
  @media (min-width: 768px) {
    max-width: 100%;
    border: 2px solid var(--red);
    background: transparent;
    color: var(--red);

    a {
      color: var(--red);
    }

    a:hover {
      color: var(--red);
    }
  }
`;

const CTA = styled.h4`
  font-weight: 500;
  color: #fff;
  font-size: 1rem;
  line-height: 1.2;

  @media (min-width: 768px) {
    color: var(--gray-text);
  }
`;
