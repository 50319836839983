import React from "react";
import ReactDom from "react-dom";
import styled from "styled-components";
import { VscClose, VscArrowLeft, VscArrowRight } from "react-icons/vsc";

const Modal = ({ data, current, open, onClose, next, prev }) => {
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <Overlay>
        <BG onClick={onClose} />
        <Button onClick={onClose}>
          <VscClose />
        </Button>
        <Img src={`${data[current]}`} key={current} alt="" />

        <Navigation>
          <ButtonLeft onClick={prev}>
            <VscArrowLeft />
          </ButtonLeft>

          <ButtonRight onClick={next}>
            <VscArrowRight />
          </ButtonRight>
        </Navigation>
      </Overlay>
    </>,
    document.getElementById("portal")
  );
};

export default Modal;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

const Button = styled.button`
  position: fixed;
  top: 0;
  right: 0;
  font-size: 2rem;
  padding: 20px 20px;
  cursor: pointer;
  border: none;
  color: #fff;
  z-index: 15;
  background: transparent;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const ButtonLeft = styled(Button)`
  position: relative;
  padding-left: 20px;
  right: inset;
  background: #010201;
  @media (min-width: 768px) {
    background: transparent;
  }
`;

const ButtonRight = styled(Button)`
  position: relative;
  padding-right: 20px;
  right: inset;
  background: #010201;
  @media (min-width: 768px) {
    background: transparent;
  }
`;

const Navigation = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;

  z-index: 14;
  @media (min-width: 768px) {
    position: absolute;
    justify-content: space-between;
  }
`;

const BG = styled.div`
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 12;
`;

export const Img = styled.img`
  z-index: 13;
  align-self: center;
  max-height: 100vh;
  @media (min-width: 768px) {
    max-width: 900px;
  }
`;
