import React, { useState } from "react";
import styled from "styled-components";
import Modal from "./Modal";

const ImageGallery = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState(0);

  const length = data.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  return (
    <>
      <Gallery>
        {data.map((image, i) => (
          <img
            src={image}
            key={i}
            alt=""
            onClick={() => {
              setIsOpen(true);
              setCurrent(i);
            }}
          ></img>
        ))}
      </Gallery>
      <Modal
        data={data}
        current={current}
        next={nextSlide}
        prev={prevSlide}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      ></Modal>
    </>
  );
};

export default ImageGallery;

const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-template-rows: repeat(auto-fill, auto);
  /* gap: 2px; */
  object-fit: cover;

  img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    padding: 2px;

    cursor: pointer;

    :hover {
      background: var(--blue);
      transition: all 0.25s ease-in-out;
    }
  }
`;
