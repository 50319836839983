import React from "react";
import styled from "styled-components";

const Stats = () => {
  return (
    <Container id="stats">
      <Banner>In Seven trips GLORI has...</Banner>

      <StatContainer>
        <Stat>
          <Img src="images/volunteer.jpg" />

          <StatDiv color="var(--mint)">
            <p>
              Enlisted <No>123</No> volunteers
            </p>
          </StatDiv>
        </Stat>

        <Stat>
          <Img src="images/consult.jpg" />
          <StatDiv color="var(--purple)">
            <p>
              visited <No>414</No> patients
            </p>
          </StatDiv>
        </Stat>

        <Stat>
          <Img src="images/surgeries.jpg" />
          <StatDiv color="var(--amber)">
            <p>
              And completed <No>211</No> surgeries
            </p>
          </StatDiv>
        </Stat>
      </StatContainer>
    </Container>
  );
};

export default Stats;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4vw;
  min-height: 100vh;
  @media (min-width: 768px) {
    height: initial;
  }
`;

const StatContainer = styled.div`
  display: flex;
  gap: 4vw;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    height: auto;
  }
`;

const Stat = styled.div`
  width: 100%;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.35);
`;

const Img = styled.img`
  aspect-ratio: 2/1;
  width: 100%;
  object-fit: cover;
`;

const StatDiv = styled.div.attrs((props: { color: string }) => props)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => props.color};
  width: 100%;
  aspect-ratio: 2/1;
  padding: 1rem;
  text-align: center;
  color: white;
  text-transform: uppercase;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  p {
    font-size: 1.6187rem;
    font-weight: 900;
  }
`;

const No = styled.h2`
  color: white;
  line-height: 1;
  font-size: 3rem;
  font-weight: 900;
`;

const Banner = styled.h2`
  color: var(--blue);
  font-weight: 900;
  font-size: 1.6187rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
`;
