export const imgnov2022 = [
  "images/Nov2022/IMG_0717.JPG",
  "images/Nov2022/IMG_0416.jpg",
  "images/Nov2022/IMG_1018.JPG",
  "images/Nov2022/20221118_071519.jpg",
  "images/Nov2022/IMG_0806.JPG",
  "images/Nov2022/20221115_163829.jpg",
  "images/Nov2022/IMG_1047.JPG",
  "images/Nov2022/IMG_0795.jpg",
  "images/Nov2022/IMG_0912.JPEG",
  "images/Nov2022/IMG_0801.jpg",
  "images/Nov2022/20221117_094342.jpg",
  "images/Nov2022/IMG_0872.jpg",
  "images/Nov2022/IMG_0724.JPG",
  "images/Nov2022/IMG_0955.jpg",
  "images/Nov2022/20221113_073529.jpg",
  "images/Nov2022/20221113_090323.jpg",
  "images/Nov2022/20221117_073048.jpg",
  "images/Nov2022/20221114_084315.jpg",
  "images/Nov2022/IMG_0968.jpg",
  "images/Nov2022/20221113_132541.jpg",
  "images/Nov2022/IMG_0936.jpg",
  "images/Nov2022/IMG_0758.JPEG",
  "images/Nov2022/IMG_0909.JPEG",
];
