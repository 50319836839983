export const imgNov2023 = [
  "images/Nov2023/IMG_8623.jpg",
  "images/Nov2023/IMG_8541.jpg",
  "images/Nov2023/IMG_6291.jpg",
  "images/Nov2023/IMG_6353.jpg",
  "images/Nov2023/IMG_3787.jpg",
  "images/Nov2023/IMG_6105.jpg",
  "images/Nov2023/IMG_6227.jpg",
  "images/Nov2023/IMG_0051.jpg",
  "images/Nov2023/IMG_0973.jpg",
  "images/Nov2023/IMG_0046.jpg",
];
