import React, { useState, useEffect, useRef, useContext } from "react";
import { narratives } from "../Data/narrativeData";
import styled from "styled-components";
import { NarContext } from "../ContentComponents/NarContext";

const QuoteSlider = ({ openNarratives }) => {
  const [index, setIndex] = useState(0);
  const data = narratives;
  const delay = 5000;
  const timeoutRef = useRef(null);

  const GoToSlide = (i) => {
    setIndex(i);
  };

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      setIndex((index) => {
        return index === data.length - 1 ? 0 : index + 1;
      });
    }, delay);
  }, [index, data.length]);

  const { setAuthor } = useContext(NarContext);

  const handleClick = (props) => {
    openNarratives();
    setAuthor(props);
  };

  return (
    <Container>
      <SlideContainer>
        <Quote>
          <p>{narratives[index].quote}</p>
          <Attributes>
            <Author>
              <Name>{narratives[index].name}</Name>
              <Role> {narratives[index].role}</Role>
            </Author>
            <CTA onClick={() => handleClick(narratives[index].position)}>
              Click Here to Read More
            </CTA>
          </Attributes>
          <Dots>
            {data.map((slide, i) => (
              <Dot
                key={i}
                className={index === i ? "active" : null}
                onClick={() => GoToSlide(i)}
              ></Dot>
            ))}
          </Dots>
        </Quote>
      </SlideContainer>
    </Container>
  );
};

export default QuoteSlider;

const Container = styled.div`
  display: block;
  margin: 0 auto;
  position: relative;
`;

const Attributes = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  /* flex-grow: 1; */
`;

const Author = styled.div`
  margin-right: 30px;
  align-self: end;
`;

const Role = styled.h4``;

const CTA = styled.a`
  color: #f1f1f1;
  text-decoration: underline;
  align-self: end;
  cursor: pointer;
`;

const Name = styled.h3`
  color: #f1f1f1;
  font-size: 1.6187rem;
  font-family: ivypresto-display, serif;
  font-weight: 700;
  letter-spacing: -0.5px;
  text-transform: uppercase;
`;

const Dot = styled.div`
  border-radius: 5px;
  height: 10px;
  width: 10px;
  cursor: pointer;
  background: #f1f1f1;
`;

const Dots = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 5px;
  /* position: absolute; */
  /* bottom: 5px;
  left: 20px; */

  .active {
    background: var(--blue);
    border: 1px solid #f1f1f1;
  }
`;

const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: url(images/nar_bg_mobile.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 3rem;

  @media (min-width: 768px) {
    background: url(images/nar_bg_desktop.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

const Quote = styled.div`
display: flex;
flex-direction: column;
  z-index: 0;
  padding: 2rem;
  max-width: 85ch;
    color:  #f1f1f1;
  align-self: center;
  min-height: 60vh;

  p {
    flex-grow: 2;
    font-size: 1.2rem;
  }

  @media (min-width: 768px) {
  max-width: 75vw;
    p{
      font-size: 1.3rem;
    /* font-family: ivypresto-text, serif; */
    /* font-weight: 600; */
    /* font-style: italic; */
  }
`;
