import React from "react";
import { Title } from "../Styles";
import styled from "styled-components";
import { IoNewspaperSharp } from "react-icons/io5";

function NewsAndUpdates() {
  return (
    <>
      <Title>GLORI News and Updates</Title>

      <Grid>
        {newsData.map((item, index) => (
          <NewsItem key={index}>
            <a href={item.url} target="_blank" rel="noreferrer">
              <img src={item.imgurl} alt={item.title} />
              <div className="tag">
                <Headline>{item.title}</Headline>
                <p>
                  {item.author ? <Byline>By {item.author},</Byline> : null}{" "}
                  <Pub>{item.publication}</Pub>
                </p>
              </div>
            </a>
          </NewsItem>
        ))}
      </Grid>
      <h3>GLORI Newsletters</h3>
      {newsletters.map((newsletter, index) => (
        <NewsletterItem key={index}>
          <IoNewspaperSharp />

          <a href={newsletter.url} target="_blank" rel="noreferrer">
            {newsletter.title}
          </a>
        </NewsletterItem>
      ))}
    </>
  );
}

export default NewsAndUpdates;

const NewsletterItem = styled.div`
  display: flex;
  gap: 5px;
  color: var(--blue);
  align-items: center;

  a {
    text-decoration: underline;
    color: var(--blue);
  }

  a:hover {
    text-decoration: none;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
`;

const Headline = styled.h3`
  text-transform: capitalize;
  color: #36382e;
  width: 80%;
`;

const NewsItem = styled.div`
  margin-bottom: 1em;
  .tag {
    margin-top: 0.25rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .tag h3 {
    text-decoration: none;
  }
`;

const Byline = styled.span``;

const Pub = styled.span`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-style: italic;
`;

const newsData = [
  {
    title: "New! Glori shirts available at our online storefont!",

    url: "https://glori.bigcartel.com/",
    imgurl: "./images/gloritees.webp",
  },
  {
    title:
      "For One Mount Sinai Orthopedist, Humanitarian Work Has Its Own Reward",
    publication: "Mount Sinai",
    url: "https://reports.mountsinai.org/article/ortho-2024-humanitarian#content",
    imgurl: "./images/ORTHO-2024--Humanitarian_Hero.jpg",
  },
  {
    title: "On a mission: orthopaedic surgeons look to expand program abroad",
    author: "Noah Fromson",
    publication: "Michigan Health",
    url: "https://healthblog.uofmhealth.org/bones-muscles-joints/on-a-mission-orthopaedic-surgeons-look-to-expand-program-abroad",
    imgurl: "./images/Surgery-Staff-Smiling-Banner-Hospital.jpg",
  },
];

const newsletters = [
  {
    title: "Sept 2023",
    url: "./newsletter/GLORI_newsletter_September 2023_FULL.jpg",
  },

  { title: "Fall 2023", url: "./newsletter/GLORI_newsletter_fall2023.pdf" },

  { title: "May 2023", url: "./newsletter/glori newsletter May 2023.pdf" },
];
