import { createGlobalStyle } from "styled-components";
import styled from "styled-components";

export const GlobalStyle = createGlobalStyle`

html, :root{
    font-size: 14px;
  line-height: 18px;
}

body{
    margin: 0;
    background-color: #fff;

    font-family: sofia-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1em;
    line-height: 1.286em;
    color: #36382E;
    }

    :root {
    --black-text: #36382E;
    --gray-text: #6E6969;
    --red: #FF4634;
    --dark-red: #CC1200;
    --blue: #2578DB;
    --amber: #F2BB05;
    --mint: #59C9A5;
    --purple: #2A1E5C;
    --dark-amber: hsl(46, 96%, 42%);

  }

a{  
  text-decoration: none;
  color: #6E6969;
}

a:hover{
  color: #36382E;
}

h1{
    font-size: 2.643em;
    line-height: 1.46em;
    font-weight: 500;
    color: var(--gray-text);

}

h2{
    font-size: 1.64285714em;
    line-height: 1.56521739em;
    color: var(--black-text);
}

h3{
    font-weight: 700;
    font-size: 1em;
    line-height: 1.28571429em;
    color: #2578DB;
}


p{
    font-size: 1em;
    line-height: 1.28571429em;
    max-width: 65ch;
}

figcaption{
    font-size: .9em;
    line-height: 1em;
    letter-spacing: .5px;
    color: #0f0f0f;
}

ul {
    list-style-type: square;
    list-style-position: outside;
    max-width: 65ch;

  }

  ul li {
    margin-left: 2rem;
    margin-right: 2rem;
    padding-left: .5rem;
    margin-top: 0.5rem;

  }

strong{
  font-weight: 900;
}

section{
  background: white;
}


@media (min-width: 768px) {
  div.section-partition{
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 50vw;
}
}

.logo-container svg path{
 fill: #fff;

 @media (min-width: 768px) {
  fill: var(--blue)
 }
}

/* Slideshow */
.slideshowDots {
  text-align: left;
}
.slideshowDot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  cursor: pointer;
  margin: 15px 7px 0px;
  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #6a0dad;
}

`;

export const Anchor = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  overflow: scroll;
  scroll-snap-type: y proximity;
  position: relative;
`;

export const Text = styled.div`
  padding: 0 1.6187rem;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media (min-width: 768px) {
    padding-top: 2rem;
    padding-left: 4vw;
    padding-right: 7vw;
  }
`;

export const Divider = styled.div`
  padding-bottom: 0.5rem;
  border-bottom: 1px solid lightgrey;
`;

export const Title = styled.h3`
  font-weight: 900;
  font-size: 1.6187rem;
  margin-bottom: 1rem;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const GradientBG = styled.div`
  position: absolute;

  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgb(30, 30, 30);
  background: linear-gradient(
    0deg,
    rgba(30, 30, 30, 1) 0%,
    rgba(30, 30, 30, 0) 76%
  );

  @media (min-width: 768px) {
    display: none;
  }
`;

export const SlideButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 45px;

  :active {
    scale: 0.95;
  }
`;

export const Button = styled.button`
  background: none;
  border: none;
  border-radius: 21px;
  padding: 5px 10px;

  font-weight: 700;
  text-transform: uppercase;
  color: var(--blue);

  cursor: pointer;

  :active {
    scale: 0.95;
  }
`;

export const Btn = styled.a`
  margin-top: 1rem;
  display: inline-block;
  padding: 10px 30px 8px 30px;
  background-color: var(--amber);
  color: var(--black-text);
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  transition: background-color 0.3s ease;

  align-self: center;

  :hover {
    background-color: var(--dark-amber);
  }

  :active {
    scale: 0.95;
  }
`;
