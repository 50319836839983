export const imgApril2024 = [
  "images/April2024/IMG_0463.jpg",
  "images/April2024/IMG_1295.jpg",
  "images/April2024/IMG_1319.jpg",
  "images/April2024/IMG_5743.jpg",
  "images/April2024/IMG_5752.jpg",
  "images/April2024/IMG_6367.jpg",
  "images/April2024/IMG_20240423_192025258.jpg",
  "images/April2024/IMG_20240424_095225315.jpg",
  "images/April2024/IMG_20240424_120703155.jpg",
  "images/April2024/IMG_20240424_123726552.jpg",
  "images/April2024/IMG_20240424_134949485.jpg",
  "images/April2024/IMG_20240425_091838264.jpg",
  "images/April2024/IMG_20240425_102811805.jpg",
  "images/April2024/IMG_20240425_112153493.jpg",
];
