import React from "react";
import Logo from "../Components/Logo";
import TreeLogo from "../Assets/logo-tree.png";
import styled from "styled-components";
import CTAButton from "../Components/CTAButton";
import { AiFillMail, AiFillInstagram, AiFillFacebook } from "react-icons/ai";

const Header = () => {
  return (
    <>
      <HeaderContainer className="text">
        <header>
          <img src={TreeLogo} alt="Glori Tree logo" className="tree-logo" />

          <div className="logo">
            <Logo />
          </div>
          <div className="mission-statement">
            <p>
              Our mission is to ease suffering, improve health and promote
              well-being for those in need.
            </p>
            <p>
              GLORI supports the social and economic infrastructure of the
              Dominican Republic by providing free orthopedic health care for
              poor Dominican and Haitian communities.
            </p>
          </div>

          <CTAButton>
            GLORI is a 501(c)(3) charity so all your donations are tax
            deductible and we are so grateful to any contributions you can make
            to continue providing top notch orthopedic care to people in need in
            the Dominican Republic.
          </CTAButton>
        </header>
      </HeaderContainer>

      <Contact>
        <Item>
          <AiFillFacebook />
          <a
            href="https://www.facebook.com/greatlakesori"
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
        </Item>
        <Item>
          <AiFillInstagram />

          <a
            href="https://www.instagram.com/greatlakesortho_international/"
            target="_blank"
            rel="noreferrer"
          >
            @greatlakesortho_international
          </a>
        </Item>

        <Item>
          <AiFillMail />

          <a href="mailto:greatlakesori@yahoo.com">greatlakesori@yahoo.com</a>
        </Item>
      </Contact>
    </>
  );
};

export default Header;

const HeaderContainer = styled.div`
  z-index: 3;
  position: relative;
  background-image: url("./images/tendermoment.jpg");
  background-image: linear-gradient(
      0deg,
      rgba(30, 30, 30, 1) 0%,
      rgba(30, 30, 30, 0) 76%
    ),
    url("./images/tendermoment.jpg");

  background-size: cover;
  background-position: center top;
  min-height: 100vh;

  header {
    z-index: 4;
    padding: 0 1.6187rem;
    padding-bottom: 60px;
    display: inline-flex;
    flex-direction: column;
    justify-content: end;
    gap: 1rem;
    min-height: 100vh;
  }

  .logo {
    order: 2;
  }

  .logo svg {
    max-width: 40vh;
  }

  .tree-logo {
    max-width: 150px;
    order: 1;
  }

  p {
    color: #fff;
    font-size: 1rem;
    line-height: 1.2;
  }
  .mission-statement {
    order: 3;
  }

  .mission-statement p:first-of-type {
    margin-bottom: 1rem;
    font-size: 1.6rem;
    font-weight: 700;
  }

  @media (min-width: 768px) {
    background-image: none;
    header {
      position: unset;
      padding: 3rem 7vw calc(2vw + 1.6187rem) calc(2vw + 1.6187rem);
      min-height: 100vh;
      overflow: scroll;
      /* border-bottom: 1px solid #f1f1f1; */
    }

    .logo {
      order: 1;
    }

    .mission-statement {
      order: 2;
      font-size: 1.6187rem;
      flex-grow: 100;
    }

    .mission-statement p:first-of-type {
      color: #00adef;
    }

    .mission-statement p {
      order: 4;
      color: var(--gray-text);
      max-width: 75ch;
      /* text-align: center; */
    }

    .tree-logo {
      width: auto;
      margin: 0 auto;
      order: 3;
    }

    .CTA {
      order: 5;
    }
  }
`;

const Contact = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1rem 1.6187rem;
  color: var(--blue);
  gap: 14px;

  @media (min-width: 768px) {
    flex-direction: row;
    padding-top: 2rem;
    padding-left: 4vw;
    padding-right: 7vw;
  }
`;

const Item = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  a {
    color: var(--blue);
  }
`;
