export const img2021 = [
  "images/2021/ss_01.jpg",
  "images/2021/ss_02.jpg",
  "images/2021/ss_03.jpg",
  "images/2021/ss_04.jpg",
  "images/2021/ss_05.jpg",
  "images/2021/ss_06.jpg",
  "images/2021/ss_07.jpg",
  "images/2021/ss_08.jpg",
  "images/2021/ss_09.jpg",
  "images/2021/ss_10.jpg",
  "images/2021/ss_11.jpg",
  "images/2021/ss_12.jpg",
  "images/2021/ss_13.jpg",
  "images/2021/ss_14.jpg",
  "images/2021/ss_15.jpg",
  "images/2021/ss_16.jpg",
  "images/2021/ss_17.jpg",
  "images/2021/ss_18.jpg",
  "images/2021/ss_19.jpg",
  "images/2021/ss_20.jpg",
  "images/2021/ss_21.jpg",
  "images/2021/ss_22.jpg",
];
