import React from "react";
import styled from "styled-components";

const DonateBug = () => {
  return (
    <Button>
      <a
        href="http://paypal.com/us/fundraiser/charity/4681460"
        target="_blank"
        rel="noreferrer"
      >
        Donate
      </a>
    </Button>
  );
};

export default DonateBug;

const Button = styled.button`
  display: none;

  @media (min-width: 768px) {
    display: block;

    background-color: var(--red);
    border: 1px solid var(--dark-red);
    border-radius: 21px;
    padding: 6px 10px 5px 10px;
    align-self: center;

    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    height: min-content;

    cursor: pointer;

    :active {
      transform: scale(0.95);
    }
    a {
      color: #fff;
    }

    a:hover {
      color: #fff;
    }
  }
`;
