export const img2022 = [
  "images/2022/001.jpg",
  "images/2022/002.jpg",
  "images/2022/67250825572__772AD01D-0AF7-409A-A01F-44524BFC23E2.jpg",
  "images/2022/consult.jpg",
  "images/2022/goteam.jpg",
  "images/2022/IMG_0328.jpg",
  "images/2022/IMG_0332.jpg",
  "images/2022/IMG_0347.jpg",
  "images/2022/IMG_0406.jpg",
  "images/2022/IMG_0440.jpg",
  "images/2022/IMG_0528.jpg",
  "images/2022/IMG_0576.jpg",
  "images/2022/IMG_0710.jpg",
  "images/2022/IMG_1177.jpg",
  "images/2022/IMG_3425.jpg",
];
