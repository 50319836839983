import React from "react";
import { Text, Title, FlexContainer, Btn } from "../Styles";
import Donations from "./Donations";

const Goals = () => {
  return (
    <div className="section-partition">
      <Text>
        <Title>Our Goals</Title>
        <ul>
          <li>
            Provide planning, staffing and support for 1-2 orthopedic missions
            to El Buen Samaritano Hospital La Romana DR per year
          </li>
          <li>
            Coordinate with other orthopedic humanitarian missions providing
            services at El Buen Samaritano Hospital
          </li>
          <li>
            Work with local physicians and medical staff in exchanging ideas and
            techniques in the care of these patients
          </li>
          <li>
            Use virtual technology in providing continuity of care throughout
            the year with patients and local medical staff
          </li>
        </ul>
      </Text>
      <Text>
        <Title>Nitty Gritty</Title>
        <p>
          We have 2 operating rooms and can generally perform 3
          surgeries/room/day which means we can get 6-8 cases done a day. We do
          surgeries Monday–Thursday, so on average we do 24 surgeries each visit
          in addition to clinic pre and post surgery care.
        </p>
      </Text>
      <Text>
        <img src="./images/2022/IMG_0873.jpg" alt="Hard Work requires rest" />
      </Text>

      <Text>
        <Title>Long-Term Goals</Title>
        <FlexContainer>
          <p>
            With more funding we could expand the mission trip to incorporate a
            knowledge exchange with Dominican surgeons and surgical residents to
            create a deeper impact where the American team teaches the Dominican
            team their techniques and the Dominicans share their skills, tools
            and resources as well.
          </p>
          <p>
            The Dominican surgeons who work at the charitable hospital have
            other full-time hospital jobs because the work with the charitable
            hospital is either volunteer or paid extremely low wages. With
            increased funding we could support our team’s costs while also
            contributing towards the Dominican health care providers wages.
          </p>
        </FlexContainer>
      </Text>
      <Donations />
      <Text>
        <FlexContainer>
          <Title>Help us raise funds by purchasing a GLORI tee shirt</Title>
          <a
            href="https://glori.bigcartel.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="./images/gloritees.jpg" alt="Glori T-shirt" />
          </a>
          <Btn href="https://glori.bigcartel.com">
            Visit Our Online Storefront
          </Btn>
        </FlexContainer>
      </Text>
    </div>
  );
};

export default Goals;
