export const imgApril2023 = [
  "images/April2023/GLORI shirts team .jpg",
  "images/April2023/hand surgery prep.JPG",
  "images/April2023/in action surgery.JPG",
  "images/April2023/Megan in back of OR.JPG",
  "images/April2023/patient care.jpg",
  "images/April2023/post surgery check in.jpg",
  "images/April2023/prepping hand surgery.JPG",
  "images/April2023/surgery care.jpg",
  "images/April2023/surgery on hand.JPG",
  "images/April2023/surgery success.jpg",
  "images/April2023/surgery team.JPG",
  "images/April2023/team in action.JPG",
  "images/April2023/team in surgery.JPG",
  "images/April2023/team photo.JPG",
  "images/April2023/team prep.jpg",
];
